function Article() {
  return (
    <div className="Article">
      <p>
      Breathe deep and enter a digital paradise, Where serene apes in meditative guise, Bring mindfulness and relaxation to your days, A sanctuary from the world’s frenetic pace.
      </p>
      <p className="paragraph">
        To make this site, we are checking the hash of every image uploaded to Ordinals and comparing it against the original 500 Apes images. The links to Bitcoin Apes are the first-seen inscriptions (lowest ID) that contain these hashes on
        Ordinals.
      </p>
    </div>
  );
}

export default Article;
